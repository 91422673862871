import React, { useEffect, useState } from "react";
import {
  // Button,
  // FormControl,
  // FormControlLabel,
  Grid,
  // Radio,
  // RadioGroup,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.css";

// const days = [
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
//   "Sunday",
// ];

// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

function PaymentDetails() {
  // const [skipPayment, setSkipPayment] = useState(false);
  // const [paymentOption, setPaymentOption] = useState("1");
  const [appointment, setAppointment] = useState({});
  // const [paymentScreen, setPaymentScreen] = useState(false);
  const [balance, setBalance] = useState(20);
  const [estimate, setEstimate] = useState(25);
  const [copay, setCopay] = useState(8);
  const [deductible, setDeductible] = useState(10);
  const [allowed, setAllowed] = useState(20);
  const newPatient = parseInt(localStorage.getItem("apmtTypeId"));

  function getDate(date) {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (Number.isNaN(year)) {
      return null;
    }
    return [month, day, year].join("/");
  }

  function getTime(time) {
    let t = new Date(time);
    let h = t.getHours();
    let hours;
    if (h < 10) {
      hours = "0" + h.toString();
    } else hours = h.toString();
    let mins = t.getMinutes();
    let ampm;
    if (h < 12) {
      ampm = "AM";
    } else ampm = "PM";
    return hours + ":" + mins + " " + ampm;
  }

  // const handlePay = () => {
  //   setPaymentScreen(true);
  // };

  // const handleSkip = () => {
  //   setSkipPayment(true);
  // };

  // const handleChangePayment = (e) => {
  //   setPaymentOption(e.target.value);
  // };

  useEffect(() => {
    let a = localStorage.getItem("apmtDetails");
    setAppointment(JSON.parse(a));
  }, []);

  return (
    <Grid container className="payment_details_container">
      <Grid xs={12} item className="apmt_details_container">
        <div className="apmt_details">
          <div className="apmt_details_clinicName">
            {appointment.locationName}
          </div>
          <div className="apmt_details_line2">
            {appointment.appointmentTypeName + " - " + appointment.providerName}
          </div>
          <div className="apmt_details_line3">
            {getDate(appointment.startTime) +
              " " +
              getTime(appointment.startTime)}
          </div>
        </div>
      </Grid>

      {newPatient !== 2 && (
        <div className="box_subheading">
          <div>
            Old Balance :{" "}
            <span className="box_amount">${balance.toFixed(2)}</span>
          </div>
        </div>
      )}

      <Grid xs={12} item className="details_heading">
        <div className="details_heading_text">
          Estimate of your responsibility at the time of your visit
        </div>
      </Grid>
      <Grid xs={12} item>
        <div className="payment_details_box">
          <Grid container className="payment_details_box_content">
            <Grid item xs={12} className="box_subheading">
              <div className="payment_details_row">
                Visit Type :{" "}
                <span className="box_amount">
                  {appointment.appointmentTypeName}
                </span>
              </div>
            </Grid>

            <Grid item xs={12} className="box_subheading">
              <div className="payment_details_row">
                Copay : <span className="box_amount">${copay.toFixed(2)}</span>
              </div>
            </Grid>

            <Grid item xs={12} className="box_subheading">
              <div className="payment_details_row">
                Deductible <sup className="superscript">1</sup> :{" "}
                <span className="box_amount">${deductible.toFixed(2)}</span>
              </div>
            </Grid>

            <Grid item xs={12} className="box_subheading">
              <div className="payment_details_row">
                Allowed <sup className="superscript">2</sup> :{" "}
                <span className="box_amount">${allowed.toFixed(2)}</span>
              </div>
            </Grid>

            <Grid item xs={12} className="box_subheading">
              <div className="payment_details_row">
                You owe <sup className="superscript">3</sup> :{" "}
                <span className="box_amount">${estimate.toFixed(2)}</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {/* 
      <Grid xs={12} item className="payment_options_container">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="options"
            name="options"
            value={paymentOption}
            onChange={handleChangePayment}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={"Pay Previous Balance $" + balance.toFixed(2)}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label={"Pay Estimated Fee $" + estimate.toFixed(2)}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label={
                "Pay Balance + Estimated Fee $" +
                (balance + estimate).toFixed(2)
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid xs={12} item className="pay_card_button_container">
        <Button
          className="pay_card_button"
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePay}
        >
          Pay with card
        </Button>
      </Grid>
      <Grid xs={12} item className="pay_office_button_container">
        <Button
          className="pay_office_button"
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleSkip}
        >
          Pay at the office
        </Button>
      </Grid> */}
      <Grid xs={12} item className="explanations_container">
        <div className="explanation_text">1 Outstanding deductible</div>
        <div className="explanation_text">
          2 Estimate of your out of pocket expense for this visit. This is a
          Good Faith Estimate (GFE) based on our understanding of what you will
          owe after insurance pays their portion. If insurance denies this
          claim, you could be liable for the total amount.
        </div>
        <div className="explanation_text">
          3 Estimate of what your insurance will allow
        </div>
        <div></div>
      </Grid>
    </Grid>
  );
}

export default PaymentDetails;
